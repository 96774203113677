/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { colors } from "common/scss/themes";
import { ErrorMessage, useField } from "formik";
import React, { useState } from "react";
import Checkbox from "../../FormComponents/Checkbox";
import InputBase from "./utils/InputBase";

function FormikSelect({
  hidePlaceHolder = false,
  valueToDisplaySelectOne = "",
  name = "",
  label = "",
  options = [
    {
      label: "",
      value: ""
    }
  ],
  required = false,
  selectLabel = "",
  multiple = false,
  withCheckbox = false,
  onChange = (event: any) => {
    /* eslint-disable no-console */
    console.log(event.target.value);
  },
  ...props
}) {
  const [field, meta] = useField(name);

  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const handleChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = window.setTimeout(() => {
      field.onChange(event);
    }, 200);

    setTimeoutId(newTimeoutId);
  };

  return (
    <FormControl fullWidth>
      <Box display="flex" alignItems="center">
        {label && (
          <label
            htmlFor={name}
            className="mb-1 block font-sans text-sm font-medium text-gray-10"
          >
            {label}
            {required && (
              <span className="text-sm text-default-error-9">*</span>
            )}
          </label>
        )}
      </Box>
      <Select
        {...props}
        name={name}
        value={field.value}
        input={
          <InputBase
            value={field.value}
            inputComponent="input"
            inputProps={{
              id: name,
              name,
              ...props
            }}
          />
        }
        multiple={multiple}
        onChange={handleChange}
        onBlur={field.onBlur}
        error={meta.touched && Boolean(meta.error)}
        MenuProps={{
          classes: {
            paper: "rounded-md mt-1",
            list: "p-px"
          }
        }}
      >
        <MenuItem
          hidden={hidePlaceHolder}
          value={valueToDisplaySelectOne}
          className="p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1"
          sx={{
            "&.Mui-selected": {
              color: colors.primary[8],
              backgroundColor: "white",
              "&:hover, &.Mui-focusVisible": {
                backgroundColor: colors.primary[1]
              }
            }
          }}
        >
          {selectLabel}
        </MenuItem>
        {options.map((option: any) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className="h-[36px] min-h-[36px] p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1"
            sx={{
              "&.Mui-selected": {
                color: colors.primary[8],
                backgroundColor: "white",
                "&:hover, &.Mui-focusVisible": {
                  backgroundColor: colors.primary[1]
                }
              }
            }}
          >
            {withCheckbox && (
              <Checkbox
                label={option.label}
                checked={field.value.includes(option.value)}
                onChange={(event: any) => {
                  const newValue = [...field.value];
                  if (event.target.checked) {
                    newValue.push(option.value);
                  } else {
                    newValue.splice(newValue.indexOf(option.value), 1);
                  }
                  field.onChange({ target: { name, value: newValue } });
                }}
              />
            )}
            {!withCheckbox && option.label}
          </MenuItem>
        ))}
      </Select>
      <ErrorMessage
        component={FormHelperText}
        name={name}
        render={(msg) => (
          <Typography
            component="span"
            className="px-2 py-1 font-sans text-xs font-medium text-default-error-9"
          >
            {msg}
          </Typography>
        )}
      />
    </FormControl>
  );
}

export default FormikSelect;
