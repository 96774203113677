import { callExternalApi } from "../external-api.service";
import { User } from "./models/user.interface";
// eslint-disable-next-line import/no-cycle

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

const getUserData = async (accessToken: string): Promise<User | null> => {
  const config = {
    url: `${apiServerUrl}/me`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });

  if (data) {
    return data;
  }
  throw Error(`${error?.message}` || "Unknown Error");
};

const userService = {
  getUserData
};

export default userService;
