import {
  FormControlLabel,
  Switch as MuiSwitch,
  SwitchProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "common/scss/themes";
import React from "react";

export interface Props {
  label?: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
}

interface HaistackSwitchProps extends SwitchProps {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const HaistackSwitch = styled((props: HaistackSwitchProps) => {
  const { checked, onChange, disabled, className } = props;

  return (
    <MuiSwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      classes={{ root: "m-0" }}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={className}
    />
  );
})(() => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: colors.primary[8],
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: colors.gray[5]
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    boxShadow: "none",
    backgroundColor: colors.default.white
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: colors.gray[6],
    transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  }
}));
function Switch(props: Props) {
  const { label, labelPlacement } = props;

  return (
    <FormControlLabel
      control={<HaistackSwitch sx={{ m: 1 }} />}
      labelPlacement={labelPlacement}
      label={label}
      classes={{
        root: "m-0",
        label: "font-sans text-sm font-medium text-gray-10"
      }}
    />
  );
}

Switch.defaultProps = {
  label: "",
  labelPlacement: "end"
};

HaistackSwitch.defaultProps = {
  checked: false,
  onChange: () => {
    /* parent component should handle this */
  },
  disabled: false
};

export default Switch;
