import React from "react";

export interface Props {
  text: string;
}

function BlurText(props: Props) {
  const { text } = props;
  const blurPatterns = ["*****@****.***", "(***) ***-****", "*****"];

  const applyBlur = (input: string, pattern: string) => {
    let result = input;
    let index = input.indexOf(pattern);
    while (index !== -1) {
      const blurredPattern = `<span style="filter: blur(4px)"> ${pattern}</span>`;
      result =
        result.substring(0, index) +
        blurredPattern +
        result.substring(index + pattern.length);
      index = result.indexOf(pattern, index + blurredPattern.length);
    }
    return result;
  };

  let blurredText = text;
  blurPatterns.forEach((pattern) => {
    blurredText = applyBlur(blurredText, pattern);
  });

  return (
    <div
      className="blurred-text"
      dangerouslySetInnerHTML={{ __html: blurredText }}
    />
  );
}

export default BlurText;
