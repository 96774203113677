import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

function PageLoader() {
  return (
    <Box display="flex" justifyContent="center" py={5}>
      <CircularProgress color="warning" />
    </Box>
  );
}

export default PageLoader;
