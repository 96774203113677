import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNotification } from "../notifications/notificationsSlice";
import publicService from "./public.service";

interface AsyncState {
  isLoading: boolean;
  isSuccessful: boolean;
  isError: boolean;
}

type JobState = AsyncState;

const initialState: JobState = {
  isLoading: false,
  isSuccessful: false,
  isError: false
};

type PostPartnerResponse = {
  jobId: string;
  attorneyId: number;
  status: number;
};

export const postPartnerResponse = createAsyncThunk(
  "public/postPartnerResponse",
  async (data: PostPartnerResponse, thunkAPI) => {
    try {
      const { jobId, attorneyId, status } = data;
      const response = await publicService.postPartnerResponse(
        jobId,
        attorneyId,
        status
      );

      thunkAPI.dispatch(
        addNotification({
          type: "success",
          message: "Status posted successfully"
        })
      );

      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          type: "danger",
          message: "Error posting status"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const postSubmitSharedTargetList = createAsyncThunk(
  "public/postSubmitSharedTargetList",
  async (token: string, thunkAPI) => {
    try {
      const response = await publicService.postSubmitSharedTargetList(token);

      thunkAPI.dispatch(
        addNotification({
          type: "success",
          message: "Shared target list submitted successfully"
        })
      );

      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          type: "danger",
          message: "Error submitting shared target list"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postPartnerResponse.pending, (state) => {
      state.isLoading = true;
      state.isSuccessful = false;
      state.isError = false;
    });

    builder.addCase(postPartnerResponse.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccessful = true;
      state.isError = false;
    });

    builder.addCase(postPartnerResponse.rejected, (state) => {
      state.isLoading = false;
      state.isSuccessful = false;
      state.isError = true;
    });
  }
});

export default publicSlice.reducer;
