import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiFiltersSlice = createApi({
  reducerPath: "apiFilters",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getFiltersData: builder.query({
      query: () => `/attorney_filters`
    }),
    getSuggestions: builder.query({
      query: ({ url, inputValue }) => `${url}?query=${inputValue}`
    }),
    getRetentionFilters: builder.query({
      query: ({ type }) => `/settings/filters?type=${type}`
    })
  })
});

// Export hooks for usage in functional components
export const {
  useGetFiltersDataQuery,
  useGetSuggestionsQuery,
  useGetRetentionFiltersQuery
} = apiFiltersSlice;
