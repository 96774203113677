import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import COLORS from "common/scss/COLORS";
import React from "react";

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? COLORS.success[9] : COLORS.error[9],
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: COLORS.success[9],
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));

function SwitchMui({
  include,
  setInclude
}: {
  include: boolean;
  setInclude: any;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInclude(!event.target.checked);
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          component="span"
          className="normal-case font-sans text-sm font-semibold text-default-black"
        >
          Include
        </Typography>
        <IOSSwitch checked={!include} onChange={handleChange} />
        <Typography
          component="span"
          className="normal-case font-sans text-sm font-semibold text-default-black"
        >
          Exclude
        </Typography>
      </Stack>
    </FormGroup>
  );
}

export default SwitchMui;
