interface PaletteColor {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: string;
  shadow?: string;
}

interface DefaultPalette {
  transparent: string;
  background: string;
  white: string;
  black: string;
  info: {
    3: string;
    7: string;
    9: string;
  };
  success: {
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
    9: string;
    10: string;
    11: string;
    12: string;
  };
  error: {
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    9: string;
  };
  warning: {
    2: string;
    6: string;
    8: string;
    9: string;
  };
  job_status: {
    new: string;
    changed: string;
    open: string;
    closed: string;
  };
}

interface Palette {
  primary: PaletteColor;
  gray: PaletteColor;
  darkGrey: PaletteColor;
  default: DefaultPalette;
  scoring: {
    low: string;
    medium: string;
    high: string;
    critical: string;
    warning: string;
    precaution: string;
    info: string;
    total: string;
  };
}

const colors: Palette = {
  primary: {
    0: "#fffcfa",
    1: "#fef9f2",
    2: "#fff2df",
    3: "#ffecd0",
    4: "#f7e0bf",
    5: "#ffdca9",
    6: "#ffcb80",
    7: "#fab041",
    8: "#da9123",
    9: "#ba7000",
    10: "#925700",
    shadow: "rgba(218,145,35,0.32)"
  },
  gray: {
    0: "#f9fafb",
    1: "#f3f5f7",
    2: "#edf0f2",
    3: "#dfe3e7",
    4: "#cfd6dd",
    5: "#b2bdc7",
    6: "#79849a",
    7: "#5c667a",
    8: "#394356",
    9: "#17233b",
    10: "#111a2c",
    shadow: "rgba(17, 24, 28, 0.08)"
  },
  darkGrey: {
    0: "#1a1d1e",
    1: "#202425",
    2: "#26292b",
    3: "#2b2f31",
    4: "#313538",
    5: "#3a3f42",
    6: "#4c5155",
    7: "#697177",
    8: "#787f85",
    9: "#9ba1a6",
    10: "#ecedee"
  },
  default: {
    transparent: "transparent",
    background: "#E6E7E9",
    white: "#ffffff",
    black: "#000000",
    info: {
      3: "#EBF3FF",
      7: "#92BCFC",
      9: "#2173F0"
    },
    success: {
      1: "#F8FCFA",
      2: "#F1F9F6",
      3: "#EDF8F4",
      4: "#D9F2E8",
      5: "#C3EADB",
      6: "#ACE2CD",
      7: "#86D5B8",
      8: "#2AC089",
      9: "#109363",
      10: "#0E8157",
      11: "#0C6E4A",
      12: "#052E1F"
    },
    error: {
      2: "#FEF6F6",
      3: "#FDE7E9",
      4: "#FBD8DC",
      5: "#F9C0C7",
      6: "#F7A8AE",
      7: "#F99AA0",
      9: "#EF2734"
    },
    warning: {
      2: "#FFF2DF",
      6: "#FFCB80",
      8: "#DA9123",
      9: "#BA7000"
    },
    job_status: {
      new: "#5792BF",
      changed: "#D0CE6E",
      open: "#52C465",
      closed: "#424242"
    }
  },
  scoring: {
    low: "#b83d2d",
    medium: "#b87525",
    high: "#beb749",
    critical: "#89b654",
    warning: "#469495",
    precaution: "#396295",
    info: "#1b2f48",
    overall: "#7e7e7e"
  }
};

const BlueTheme = {
  primary: {}
};

const GreenTheme = {
  primary: {}
};

const OrangeTheme = {
  primary: {
    0: "#fffcfa",
    1: "#fef9f2",
    2: "#fff2df",
    3: "#ffecd0",
    4: "#f7e0bf",
    5: "#ffdca9",
    6: "#ffcb80",
    7: "#fab041",
    8: "#da9123",
    9: "#ba7000",
    10: "#925700",
    shadow: "rgba(218,145,35,0.32)"
  }
};

const RedTheme = {
  primary: {}
};

export { colors, BlueTheme, GreenTheme, OrangeTheme, RedTheme };
