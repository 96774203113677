const statusToString = (status) => {
  switch (status) {
    case 0:
      return "unrated";
    case 1:
      return "yes_to_job";
    case 2:
      return "no_to_job";
    case 3:
      return "no_to_firm";
    default:
      return "unrated";
  }
};
export default statusToString;
