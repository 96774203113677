import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiEmployeesSlice = createApi({
  reducerPath: "apiEmployees",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getAllEmployees: builder.query({
      query: () => `/api/v1/my_employees`
    }),
    getEmployees: builder.query({
      query: ({ page = 1, pageSize = 25, filters = "" }) =>
        `/api/v1/my_employees?page=${page}&per=${pageSize}${filters}`
    })
  }),
  refetchOnMountOrArgChange: true
});

// Export hooks for usage in functional components
export const { useGetAllEmployeesQuery, useGetEmployeesQuery } =
  apiEmployeesSlice;
