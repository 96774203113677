import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import usePagination from "@mui/material/usePagination";
import React from "react";
import { IconChevronLeft, IconChevronRight } from "../Icons/Icons";

export interface Props {
  isLoading: boolean;
  count: number;
}

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  gap: "8px"
});

function Pagination(props: Props) {
  const { isLoading, count } = props;

  const { items } = usePagination({
    count
  });

  const style = (selected: boolean) => {
    return selected
      ? "min-w-full w-[32px] h-[32px] rounded-md bg-primary-8 text-default-white font-sans text-sm font-medium" +
          " hover:bg-primary-8"
      : "min-w-full w-[32px] h-[32px] bg-default-white text-gray-10 font-sans text-sm font-medium" +
          " hover:bg-primary-4" +
          " focus:bg-primary-5";
  };

  const iconStyles =
    "min-w-full w-[32px] h-[32px] rounded-md border border-solid border-gray-4" +
    " hover:bg-gray-3";

  return (
    <Box display="flex" alignItems="center" gap="8px">
      <Typography className="text-gray-10 font-sans text-sm font-medium">
        Page:
      </Typography>
      <nav>
        <List>
          {items.map(({ page, type, selected }) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = (
                <Button
                  id="more"
                  type="button"
                  className={style(selected)}
                  disabled={isLoading}
                >
                  <Typography>...</Typography>
                </Button>
              );
            } else if (type === "page") {
              children = (
                <Button id="page" type="button" className={style(selected)}>
                  {page}
                </Button>
              );
            } else {
              children =
                type === "previous" ? (
                  <Button id="previous" type="button" className={iconStyles}>
                    <IconChevronLeft stroke="#000" width={14} />
                  </Button>
                ) : (
                  <Button id="next" type="button" className={iconStyles}>
                    <IconChevronRight stroke="#000" width={14} />
                  </Button>
                );
            }

            return <li key={page}>{children}</li>;
          })}
        </List>
      </nav>
    </Box>
  );
}

export default Pagination;
