import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";
import { setCurrentFirm, setImpersonateSuccessful } from "./impersonateSlice";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiImpersonateSlice = createApi({
  reducerPath: "apiImpersonate",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getFirms: builder.query({
      query: () => `/employers`,
      transformResponse: (response: any) => response.employers
    }),

    impersonateFirm: builder.mutation({
      query: ({ id }) => ({
        url: `/employers/${id}/login_as`,
        method: "POST",
        body: {
          employer_id: id
        }
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(setCurrentFirm(arg.firm));
          dispatch(setImpersonateSuccessful(true));
        } catch (error) {
          // Handle error here
        }
      }
    })
  })
});

// Export hooks for usage in functional components
export const { useGetFirmsQuery, useImpersonateFirmMutation } =
  apiImpersonateSlice;
