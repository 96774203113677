import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import Button from "../Button";

interface CsvExportProps {
  data: any[];
  label: string;
  buttonSize?: "sm" | "md";
  transform?: (data: any[]) => any[];
  disabled?: boolean;
}

type Props = CsvExportProps;

function CsvExport({ data, label, transform, buttonSize, disabled }: Props) {
  const [csvData, setCsvData]: any[] = useState([]);
  const csvInstance = useRef<any>(null);

  const exportMethod = () => transform && setCsvData(transform(data));

  useEffect(() => {
    if (
      csvData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  return (
    <Box>
      <Button
        id="export-csv"
        message={label}
        variant="secondary"
        size={buttonSize}
        onClick={() => {
          exportMethod();
        }}
        disabled={disabled}
      />

      {csvData.length > 0 ? (
        <CSVLink data={csvData} filename="export.csv" ref={csvInstance} />
      ) : undefined}
    </Box>
  );
}

CsvExport.defaultProps = {
  transform: () => {
    /*  */
  },
  buttonSize: "md",
  disabled: false
};

export default CsvExport;
