import { createSlice } from "@reduxjs/toolkit";

interface NotificationsState {
  open: boolean;
  message: string;
  type: "success" | "danger" | "warning" | "info";
  placement: "topRight" | "bottomRight";
}

const initialState: NotificationsState = {
  open: false,
  message: "",
  type: "success",
  placement: "bottomRight"
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (_state, action) => {
      return {
        ...initialState,
        ...action.payload,
        open: true
      };
    },
    clearNotification: (state) => ({ ...state, open: false })
  }
});

export const { addNotification, clearNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
