import { Box, Button as MuiLinkButton } from "@mui/material";
import React from "react";

export interface Props {
  message: string;
  disabled?: boolean;
  onClick: () => void;
}
function LinkButton(props: Props) {
  const { message, disabled, onClick } = props;

  const style =
    "normal-case font-sans text-sm font-semibold text-primary-8 rounded-none bg-transparent shadow-none p-0 " +
    "hover:shadow-none hover:bg-default-white " +
    "focus:rounded-md focus:text-primary-9 focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_4px] focus:shadow-primary-shadow " +
    "active:shadow-none " +
    "disabled:cursor-not-allowed disabled:shadow-none disabled:bg-default-white";
  const boxStyle =
    "w-fit border border-default-transparent hover:border-b-primary-8";
  return (
    <Box className={boxStyle} onClick={onClick}>
      <MuiLinkButton className={style} disabled={disabled}>
        {message}
      </MuiLinkButton>
    </Box>
  );
}

LinkButton.defaultProps = {
  disabled: false
};

export default LinkButton;
