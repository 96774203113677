import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiFirmSlice = createApi({
  reducerPath: "apiFirm",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getTimeForAttorneys: builder.query({
      query: (id) => `/employers/${id}/attorney_time`,
      transformResponse: (response: any) => response.time_for_attorneys_at_firm
    }),
    getJobStatistics: builder.query({
      query: (id) => `/employers/${id}/job_statistics`,
      transformResponse: (response: any) =>
        response.job_statistics.open_jobs_count
    }),
    getEmployerLocations: builder.query({
      query: (id) => `/employers/${id}/locations`,
      transformResponse: (response: any) => response.locations
    }),
    getEmployerJDYearMedian: builder.query({
      query: (id) => `/employers/${id}/jd_year_median`,
      transformResponse: (response: any) => response.jd_year_median
    }),
    getEmployerMostAttendedSchools: builder.query({
      query: (id) => `/employers/${id}/most_attended_schools`,
      transformResponse: (response: any) => response.most_attended_schools
    })
  }),

  refetchOnMountOrArgChange: true
});

// Export hooks for usage in functional components
export const {
  useGetTimeForAttorneysQuery,
  useGetJobStatisticsQuery,
  useGetEmployerLocationsQuery,
  useGetEmployerJDYearMedianQuery,
  useGetEmployerMostAttendedSchoolsQuery
} = apiFirmSlice;
