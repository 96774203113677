import { ROLES } from "./roles";

export const getUserType = (roleId: number) => {
  if (!roleId) {
    return null;
  }
  const pairs = Object.entries(ROLES);
  const userPair = pairs.filter((pair) => pair.includes(roleId));
  if (userPair?.length) {
    const [userType, userTypeId] = userPair[0];
    return {
      userType,
      userTypeId
    };
  }
  return null;
};
