import { callExternalApi } from "../external-api.service";
import { Job, JobMarketIntel, ShareTargetList } from "./models/job.interface";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

const getJob = async (
  accessToken: string,
  jobId: string
): Promise<Job | null> => {
  const config = {
    url: `${apiServerUrl}/jobs/${jobId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });

  if (data) {
    return data;
  }
  throw Error(
    `Job Error: ${error?.message ? error?.message : "Unknown Error"}`
  );
};

const getJobMarketIntel = async (
  accessToken: string,
  jobId: string
): Promise<JobMarketIntel> => {
  const config = {
    url: `${apiServerUrl}/jobs/${jobId}/market_intel`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  const { data, error } = await callExternalApi({ config });

  if (data) {
    return data;
  }
  throw Error(
    `Job Error: ${error?.message ? error?.message : "Unknown Error"}`
  );
};

const shareTargetList = async (
  accessToken: string,
  jobId: string,
  emails: string[]
): Promise<ShareTargetList> => {
  const config = {
    url: `${apiServerUrl}/api/v1/jobs/${jobId}/shared_target_lists`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      emails
    }
  };
  const { data, error } = await callExternalApi({ config });

  if (data) {
    return data;
  }
  throw Error(
    `Target Error: ${error?.message ? error?.message : "Unknown Error"}`
  );
};

const jobService = {
  getJob,
  getJobMarketIntel,
  shareTargetList
};

export default jobService;
