import { callExternalApi } from "../external-api.service";
import { Users } from "./models/users.interface";
// eslint-disable-next-line import/no-cycle

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

const getUsers = async (accessToken: string): Promise<Users | null> => {
  const config = {
    url: `${apiServerUrl}/user_management/users`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });

  if (data) {
    return data;
  }
  throw Error(`Users Error: ${error?.message}` || "Unknown Error");
};

const postUser = async (
  accessToken: string,
  user: {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    employer_id: string;
  }
): Promise<Users | null> => {
  const config = {
    url: `${apiServerUrl}/user_management/users`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      user
    }
  };

  const { data, error } = await callExternalApi({ config });

  if (data) {
    return data;
  }
  throw Error(`Users Error: ${error?.message}` || "Unknown Error");
};

const patchUser = async (
  accessToken: string,
  userId: string,
  user: {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    employer_id: string;
  }
): Promise<Users | null> => {
  const config = {
    url: `${apiServerUrl}/user_management/users/${userId}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      user
    }
  };
  const { data, error } = await callExternalApi({ config });

  if (data) {
    return data;
  }
  throw Error(`Users Error: ${error?.message}` || "Unknown Error");
};

const deleteUser = async (
  accessToken: string,
  userId: string
): Promise<Users | null> => {
  const config = {
    url: `${apiServerUrl}/user_management/users/${userId}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });

  if (data) {
    return data;
  }
  throw Error(`Users Error: ${error?.message}` || "Unknown Error");
};

const usersService = {
  getUsers,
  postUser,
  patchUser,
  deleteUser
};

export default usersService;
