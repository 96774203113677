/* eslint-disable react/jsx-props-no-spreading */
import { Box, Grid, MenuItem } from "@mui/material";
import { APP_ROUTES } from "common/constants/ROUTES";
import { colors } from "common/scss/themes";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line import/no-cycle
import { AutocompleteInput, Button, PageLoader } from "common/components";
import {
  useGetFirmsQuery,
  useImpersonateFirmMutation
} from "services/impersonate/impersonateApi";
import { Firm } from "services/impersonate/models/firm.interface";

interface ImpersonateProps {
  readonly handleModal: (value: boolean) => void;
}

function Impersonate({ handleModal }: ImpersonateProps) {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<Firm | null>(null);
  const [inputValue, setInputValue] = useState("");

  const { data: firms, isLoading } = useGetFirmsQuery(undefined);
  const [impersonateFirmQuery] = useImpersonateFirmMutation();

  const handleConfirmClick = async () => {
    if (!selectedOption) return;
    await impersonateFirmQuery({
      id: selectedOption.id,
      firm: selectedOption
    });
    navigate(APP_ROUTES.FIRM);
    handleModal(false);
  };

  return (
    <Grid container alignItems="center" spacing={2} py={2}>
      <Grid item xs={12}>
        {isLoading && <PageLoader />}
        {!isLoading && firms && (
          <AutocompleteInput
            placeholder="Select or search for a firm"
            options={firms}
            value={selectedOption}
            onChange={(event: any, newValue: any) => {
              setSelectedOption(newValue);
              if (newValue) {
                localStorage.setItem(
                  "impersonateFirm",
                  JSON.stringify(newValue)
                );
              } else {
                localStorage.removeItem("impersonateFirm");
              }
            }}
            renderOption={(props, option) => (
              <MenuItem
                {...props}
                key={option.value}
                value={option.value}
                className="h-[36px] min-h-[36px] p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1"
                sx={{
                  "&.Mui-selected": {
                    color: colors.primary[8],
                    backgroundColor: "white",
                    "&:hover, &.Mui-focusVisible": {
                      backgroundColor: colors.primary[1]
                    }
                  }
                }}
              >
                {option.firm}
              </MenuItem>
            )}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => option.firm || ""}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            id="select-firm"
            variant="primary"
            disabled={!selectedOption}
            onClick={handleConfirmClick}
            message="Select Firm"
            size="md"
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Impersonate;
