import { Box } from "@mui/material";
import { colors } from "common/scss/themes";
import React, { ChangeEvent } from "react";
import { IconClose, IconSearch } from "../../Icons/Icons";
import Input from "../Input/index";

export interface Props {
  id?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  multiline?: boolean;
  rows?: number;
  ref?: React.Ref<HTMLInputElement>;
  /* eslint-disable no-unused-vars */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClickClose?: () => void;
}

function SearchInput(props: Props) {
  const {
    id,
    value,
    placeholder,
    disabled,
    type,
    multiline,
    rows,
    ref,
    onChange,
    onClickClose
  } = props;

  return (
    <Input
      id={id}
      value={value}
      placeholder={placeholder}
      type={type}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      onChange={onChange}
      ref={ref}
      startAdornment={
        <IconSearch width={16} height={16} stroke={colors.gray[10]} />
      }
      endAdornment={
        value ? (
          <Box className="cursor-pointer" onClick={onClickClose}>
            <IconClose width={16} height={16} stroke={colors.gray[10]} />
          </Box>
        ) : (
          <IconClose width={16} height={16} stroke="transparent" />
        )
      }
    />
  );
}

SearchInput.defaultProps = {
  id: "",
  value: "",
  placeholder: "",
  disabled: false,
  type: "text",
  multiline: false,
  rows: 1,
  ref: null,
  onChange: () => {
    /* parent component should handle this */
  },
  onClickClose: () => {
    /* parent component should handle this */
  }
};

export default SearchInput;
