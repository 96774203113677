import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiJobsSlice = createApi({
  reducerPath: "apiJobs",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getJobsData: builder.query({
      query: ({ filters, page, pageSize }) =>
        `/api/v1/jobs?page=${page}&per=${pageSize}&${filters}`,
      transformResponse: (response: any) => response.jobs
    }),
    getJobsSpecialtiesOptions: builder.query({
      query: () => `/api/v1/jobs/specialties_options`
    }),
    getJobsFilters: builder.query({
      query: () => `/api/v1/jobs/filters`,
      transformResponse: (response: any) => {
        return response.reduce((acc: any, filter: any) => {
          /* eslint-disable no-param-reassign */
          acc[filter.label] = filter.options_for_select;
          return acc;
        }, {});
      }
    })
  }),
  refetchOnMountOrArgChange: true
});

// Export hooks for usage in functional components
export const {
  useGetJobsDataQuery,
  useGetJobsSpecialtiesOptionsQuery,
  useGetJobsFiltersQuery
} = apiJobsSlice;
