import { Box } from "@mui/material";
import { colors } from "common/scss/themes";
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import React from "react";

interface Props {
  readonly data: any[];
  readonly columns: any[];
  readonly initialState?: any;
  readonly manualPagination?: boolean;
  readonly setPagination?: any;
  readonly enableColumnPinning?: boolean;
  readonly enableColumnOrdering?: boolean;
  readonly enableStickyHeader?: boolean;
  readonly enableTopToolbar?: boolean;
  readonly enableBottomToolbar?: boolean;
  readonly enableColumnActions?: boolean;
  readonly muiTableBodyRowProps?: any;
  readonly selectedRows?: any;
  readonly loading?: boolean;
  readonly tableRef?: any;
  readonly state?: any;
}

function Table(props: Props) {
  const {
    data,
    columns,
    initialState,
    manualPagination,
    setPagination,
    enableColumnPinning,
    enableColumnOrdering,
    enableStickyHeader,
    enableTopToolbar,
    enableBottomToolbar,
    enableColumnActions,
    muiTableBodyRowProps,
    selectedRows,
    loading,
    tableRef,
    state
  } = props;

  const table = useMaterialReactTable({
    muiTableContainerProps: {
      ref: tableRef
    },
    data,
    columns,
    initialState,
    enableColumnPinning,
    enableColumnOrdering,
    enableStickyHeader,
    enableTopToolbar,
    enableBottomToolbar,
    enableColumnActions,
    manualPagination,
    enableSorting: false,
    state: {
      isLoading: loading,
      ...state
    },
    onPaginationChange: setPagination,
    muiTableBodyRowProps: ({ row }) => ({
      ...muiTableBodyRowProps,
      sx: {
        backgroundColor: () => {
          if (selectedRows?.some((item: any) => item.id === row.original.id)) {
            return colors.gray[2];
          }
          return "transparent";
        }
      }
    }),
    muiTableBodyCellProps: {
      sx: {
        fontFamily: "Noto Sans",
        fontSize: "14px",
        fontWeight: 500,
        verticalAlign: "middle",
        border: `1px solid ${colors.gray[2]}`,
        padding: "0",
        boxShadow: "none",
        backgroundColor: colors.default.white
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontFamily: "Noto Sans",
        fontSize: "12px",
        fontWeight: 600,
        verticalAlign: "middle",
        border: `1px solid ${colors.gray[3]}`,
        backgroundColor: colors.gray[0],
        boxShadow: "none",
        "& .Mui-TableHeadCell-Content": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        "& .Mui-TableHeadCell-Content-Labels": {
          justifyContent: "space-between",
          width: "fill-available"
        },
        "& .Mui-TableHeadCell-Content-Wrapper": {
          width: "100%"
        }
      }
    },
    icons: {
      /* ArrowDownwardIcon: (props: any) => sortIcon(props) */
    }
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
}

Table.defaultProps = {
  initialState: {
    columnPinning: { left: ["checkbox", "actions", "scoring", "name"] }
  },
  manualPagination: true,
  setPagination: undefined,
  enableColumnPinning: false,
  enableColumnOrdering: false,
  enableStickyHeader: false,
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableColumnActions: false,
  muiTableBodyRowProps: { hover: false, style: { alignItems: "center" } },
  selectedRows: null,
  loading: false,
  tableRef: null,
  state: {}
};

export default Table;
