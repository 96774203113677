import { callExternalApi } from "../external-api.service";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

const postPartnerResponse = async (
  jobId: string,
  attorneyId: number,
  status: number
): Promise<any> => {
  const config = {
    url: `${apiServerUrl}/api/v1/shared_target_lists/${jobId}/partner_responses`,
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    data: JSON.stringify({
      partner_responses: [
        {
          attorney_id: attorneyId,
          status
        }
      ]
    })
  };

  const { data, error } = await callExternalApi({ config });
  if (data) {
    return { data };
  }

  throw Error(
    `Response Error: ${error?.message ? error.message : "Unknown Error"}`
  );
};

const postSubmitSharedTargetList = async (token: string): Promise<any> => {
  const config = {
    url: `${apiServerUrl}/api/v1/shared_target_lists/${token}/complete_review`,
    method: "POST",
    headers: {
      "content-type": "application/json"
    }
  };

  const { data: responseData, error } = await callExternalApi({ config });
  if (responseData) {
    return { data: responseData };
  }

  throw Error(
    `Response Error: ${error?.message ? error.message : "Unknown Error"}`
  );
};

const PublicService = {
  postPartnerResponse,
  postSubmitSharedTargetList
};

export default PublicService;
