import { createSlice } from "@reduxjs/toolkit";
import { Firm } from "./models/firm.interface";

interface AsyncState {
  isImpersonateSuccessful: boolean;
}

interface ImpersonateState extends AsyncState {
  firms?: readonly Firm[] | null;
  currentFirm?: Firm | null;
}

const initialState: ImpersonateState = {
  isImpersonateSuccessful: false,
  firms: null,
  currentFirm: null
};

export const impersonateSlice = createSlice({
  name: "impersonate",
  initialState,
  reducers: {
    setCurrentFirm: (state, action) => {
      state.currentFirm = action.payload;
    },
    setImpersonateSuccessful: (state, action) => {
      state.isImpersonateSuccessful = action.payload;
    }
  }
});

export const { setCurrentFirm, setImpersonateSuccessful } =
  impersonateSlice.actions;
export default impersonateSlice.reducer;
