import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNotification } from "../notifications/notificationsSlice";
import jobService from "./job.service";
import { Job } from "./models/job.interface";

interface AsyncState {
  isLoadingConnections: boolean;
  isLoading: boolean;
  isSuccessful: boolean;
  isError: boolean;
}

interface JobState extends AsyncState {
  jobData?: Job | null;
  activeJob: {
    id?: number | null;
    title?: string | null;
    min_year?: string | null;
    max_year?: string | null;
    job_title?: {
      id?: number | null;
      title?: string | null;
      category?: string | null;
    };
    locations: Array<any>;
    displayed_description?: string | null;
    contact?: string | null;
    email?: string | null;
    phone?: string | null;
    created_job_skills?: Array<any>;
  } | null;
  jobMarketIntel?: Array<any> | null;
  addNewJobInitialValues: {
    job_title_id: number | null;
    title: string | null;
    min_year: string | null;
    max_year: string | null;
    practice_type: Array<string>;
    locations: Array<any>;
    displayed_description: string | null;
    skills: Array<string>;
    specialties: Array<string>;
  };
}

const initialState: JobState = {
  isLoadingConnections: false,
  isLoading: false,
  isSuccessful: false,
  isError: false,
  jobData: {
    job: null,
    meta: {}
  },
  activeJob: {
    id: null,
    title: null,
    min_year: null,
    max_year: null,
    job_title: {
      id: null,
      title: null,
      category: null
    },
    locations: [],
    displayed_description: null,
    contact: null,
    email: null,
    phone: null,
    created_job_skills: []
  },
  jobMarketIntel: [],
  addNewJobInitialValues: {
    job_title_id: null,
    title: null,
    min_year: null,
    max_year: null,
    practice_type: [],
    locations: [],
    displayed_description: null,
    skills: [],
    specialties: []
  }
};

type GetJobData = {
  accessToken: string;
  jobId: string;
};

export const getJob = createAsyncThunk(
  "job/getJob",
  async (data: GetJobData, thunkAPI) => {
    try {
      const { accessToken, jobId } = data;
      return await jobService.getJob(accessToken, jobId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

type GetJobMarketIntel = {
  accessToken: string;
  jobId: string;
};

export const getJobMarketIntel = createAsyncThunk(
  "job/getJobMarketIntel",
  async (data: GetJobMarketIntel, thunkAPI) => {
    try {
      const { accessToken, jobId } = data;
      return await jobService.getJobMarketIntel(accessToken, jobId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface ApiResponse {
  message: string;
}

export const shareTargetList = createAsyncThunk(
  "job/shareTargetList",
  async (data: any, thunkAPI) => {
    try {
      const { authToken, jobId, emails } = data;
      const response = await jobService.shareTargetList(
        authToken,
        jobId,
        emails
      );

      if ((response as ApiResponse).message) {
        thunkAPI.dispatch(
          addNotification({
            type: "success",
            message: response.message
          })
        );
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          type: "danger",
          message: "Error sharing candidates"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccessful = false;
      state.isError = false;
      state.jobData = {
        job: null,
        meta: {}
      };
    },
    setActiveJob: (state, action) => {
      state.activeJob = action.payload;
    },
    setAddNewJobValues: (state, action) => {
      state.addNewJobInitialValues = action.payload;
    },
    resetAddNewJobValues: (state) => {
      state.addNewJobInitialValues = {
        job_title_id: null,
        title: null,
        min_year: null,
        max_year: null,
        practice_type: [],
        locations: [],
        displayed_description: null,
        skills: [],
        specialties: []
      };
    },
    resetActiveJob: (state) => {
      state.activeJob = {
        id: null,
        title: null,
        min_year: null,
        max_year: null,
        job_title: {
          id: null,
          title: null,
          category: null
        },
        locations: [],
        displayed_description: null,
        contact: null,
        email: null,
        phone: null,
        created_job_skills: []
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJob.pending, (state) => {
        state.isLoading = true;
        state.isSuccessful = false;
        state.isError = false;
      })
      .addCase(getJob.fulfilled, (state, action) => {
        state.jobData = action.payload;
        state.isLoading = false;
        state.isSuccessful = true;
        state.isError = false;
      })
      .addCase(getJob.rejected, (state) => {
        state.isLoading = false;
        state.isSuccessful = false;
        state.isError = true;
        state.jobData = {
          job: null,
          meta: {}
        };
      })
      .addCase(getJobMarketIntel.fulfilled, (state, action) => {
        state.jobMarketIntel = action.payload.locations;
      })
      .addCase(getJobMarketIntel.rejected, (state) => {
        state.jobMarketIntel = [];
      });
  }
});

export const {
  reset,
  setActiveJob,
  resetActiveJob,
  setAddNewJobValues,
  resetAddNewJobValues
} = jobSlice.actions;
export default jobSlice.reducer;
