/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography
} from "@mui/material";
import React, { ReactNode } from "react";
import Button from "../Button";
import IconButton from "../IconButton";
import { IconClose } from "../Icons/Icons";

type CustomModalProps = DialogProps & {
  title?: string;
  description?: string;
  open: boolean;
  closeIcon?: boolean;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  action?: string;
  onClickAction?: () => void;
  disableOnClickAction?: boolean;
  children?: ReactNode | null;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  withFooterButtons?: boolean;
};

function Modal({
  title,
  description,
  children,
  open,
  closeIcon,
  onClose,
  action,
  onClickAction,
  disableOnClickAction,
  fullWidth,
  maxWidth,
  withFooterButtons,
  ...dialogProps
}: CustomModalProps) {
  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose(event, reason);
    }
  };

  return (
    <Box>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        {...dialogProps}
      >
        {title && (
          <DialogTitle className="!p-5">
            <Box>
              <Typography className="font-sans text-base font-semibold">
                {title}
              </Typography>
              {closeIcon ? (
                <IconButton
                  id="close"
                  variant="primary"
                  size="sm"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 20,
                    top: 19
                  }}
                  icon={<IconClose width={20} />}
                />
              ) : null}
            </Box>
            {description && (
              <Typography className="text-gray-7 font-sans text-sm font-medium">
                {description}
              </Typography>
            )}
          </DialogTitle>
        )}
        {children && (
          <DialogContent className="min-w-[600px] border-t border-b border-gray-3">
            {children}
          </DialogContent>
        )}
        {withFooterButtons && (
          <DialogActions className="!p-5">
            <Button
              id="cancel"
              variant="secondary"
              message="Cancel"
              onClick={onClose}
              size="md"
            />
            <Button
              id="submit"
              variant="primary"
              message={action}
              onClick={onClickAction}
              disabled={disableOnClickAction}
              size="md"
            />
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}

Modal.defaultProps = {
  title: "",
  closeIcon: true,
  description: "",
  fullWidth: false,
  maxWidth: "md",
  action: "Save",
  children: null,
  onClickAction: () => {
    /* parent component should handle this */
  },
  disableOnClickAction: false,
  withFooterButtons: false,
  onClose: () => {
    /* parent component should handle this */
  }
};

export default Modal;
