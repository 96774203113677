import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import List from "@mui/material/List";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { resetFilters } from "services/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "stateManagement/hooks";

// eslint-disable-next-line import/no-cycle
import UserMenu from "./utils/UserMenu";

export interface Props {
  isLogged: boolean;
  tabs: {
    id: string;
    label: string;
    name: string;
    path: string;
  }[];
  logo: string;
  offlineLogo: string;
  avatar: string;
  withSearch: boolean;
  onLogoutClick: () => void;
}

function TopNav(props: Props) {
  const { isAuthenticated } = useAuth0();

  const {
    isLogged,
    tabs,
    logo,
    offlineLogo,
    avatar,
    withSearch,
    onLogoutClick
  } = props;

  const { expirationDate } = useAppSelector((state) => state.permissions);

  const [current, setCurrent] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);

  const currentFirm = JSON.parse(
    localStorage.getItem("impersonateFirm") ?? "null"
  );

  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  const style =
    "px-2.5 py-4 font-sans text-sm font-medium text-gray-10 normal-case border-solid border-b border-default-transparent " +
    "hover:border-primary-8";

  const styleActive =
    "px-2.5 py-4 font-sans text-sm font-medium text-primary-8 normal-case border-solid border-b border-primary-8 " +
    "hover:border-primary-8";

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box className="flex items-center justify-center p-3">
        <img
          src={isLogged ? logo : offlineLogo}
          className="min-w-[28px] min-h-[31px] max-w-[31px] max-h-[31px]"
          alt="Logo"
        />
      </Box>
      <Divider />
      <List>
        {tabs.map((tab) => (
          <Link to={tab.path} key={tab.label}>
            <Typography
              component="span"
              className="block font-sans font-medium text-md px-4 py-2 hover:bg-primary-1 hover:text-primary-8 cursor-pointer"
              data-testid={tab.id}
            >
              {tab.label}
            </Typography>
          </Link>
        ))}
      </List>
    </Box>
  );

  const handleCurrent = (newValue: string) => {
    dispatch(resetFilters());
    setCurrent(newValue);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="border-solid border-b border-gray-2 h-[56px] px-6 bg-default-white"
      >
        <Box sx={{ mr: 2, display: { sm: "none" } }}>
          <IconButton
            id="drawer"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
          <Box mr={2}>
            {isLogged ? (
              <img
                src={logo}
                className="min-w-[28px] min-h-[31px] max-w-[31px] max-h-[31px]"
                alt="Logo"
              />
            ) : (
              <img
                src={offlineLogo}
                className=" min-h-[31px] max-h-[31px]"
                alt="Logo"
              />
            )}
          </Box>
          {isLogged && (
            <Box display="flex" alignItems="center">
              {tabs.map((tab) => (
                <Box onClick={() => handleCurrent(tab.path)} key={tab.path}>
                  <Link to={tab.path}>
                    <Typography
                      component="span"
                      className={
                        _.isEqual(current, tab.path) ? styleActive : style
                      }
                      id={tab.id}
                    >
                      {tab.label}
                    </Typography>
                  </Link>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {isLogged && (
          <Box display="flex" alignItems="center">
            <UserMenu
              withSearch={withSearch}
              onLogoutClick={onLogoutClick}
              avatar={avatar}
            />
          </Box>
        )}
      </Box>
      {currentFirm && isLogged && (
        <Box
          display="flex"
          justifyContent="center"
          className="z-50 py-3 px-24 border rounded-md bg-default-error-3 border-default-error-7 fixed stroke-default-error-9 top-1 left-2/4 "
          style={{
            transform: "translate(-50%, 0)"
          }}
        >
          <Typography
            component="span"
            className="font-sans font-medium text-md text-default-error-9"
          >
            Impersonating Firm: {currentFirm.firm}
          </Typography>
        </Box>
      )}

      {expirationDate && isAuthenticated && (
        <Box
          display="flex"
          justifyContent="center"
          className="z-50 py-3 px-24 border rounded-md bg-default-error-3 border-default-error-7 fixed stroke-default-error-9 top-1 left-2/4 "
          style={{
            transform: "translate(-50%, 0)"
          }}
        >
          <Typography
            component="span"
            className="font-sans font-medium text-md text-default-error-9"
          >
            Trial Account expires on:{" "}
            {new Date(expirationDate).toLocaleDateString("en-US")}
          </Typography>
        </Box>
      )}

      <Box>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 240
            }
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default TopNav;
