import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiAttorneysSlice = createApi({
  reducerPath: "apiAttorneys",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getAttorneys: builder.query({
      query: (query: string) => ({
        url: `api/v1/attorneys/search${query}`,
        method: "POST",
        body: query
      })
    })
  })
});

// Export hooks for usage in functional components
export const { useGetAttorneysQuery } = apiAttorneysSlice;
