import { FormControl, FormHelperText, Typography } from "@mui/material";
import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import Radio from "../../FormComponents/Radio";

interface Props {
  readonly label: string;
  readonly name: string;
  readonly value: string | number;
  readonly disabled?: boolean;
}

function FormikRadio(props: Props) {
  const { label, name, value } = props;
  return (
    <FormControl fullWidth>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <Radio
            {...props}
            label={label}
            value={value}
            checked={field.value === value}
            onChange={() => form.setFieldValue(name, value)}
          />
        )}
      </Field>
      <ErrorMessage
        component={FormHelperText}
        name={name}
        render={(msg) => (
          <Typography
            component="span"
            className="px-2 font-sans text-sm font-medium text-default-error-7"
          >
            {msg}
          </Typography>
        )}
      />
    </FormControl>
  );
}

FormikRadio.defaultProps = {
  disabled: false
};

export default FormikRadio;
