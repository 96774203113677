import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./common/components";

export function AuthenticationGuard({ component }) {
  const Component = withAuthenticationRequired(component, {
    // eslint-disable-next-line react/no-unstable-nested-components
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    )
  });

  return <Component />;
}
