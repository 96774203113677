import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface Props {
  /**
   * The message to be displayed in the ScoreButton
   */
  message: string | number;
  /**
   * The variant of the ScoreButton
   */
  variant:
    | "low"
    | "medium"
    | "high"
    | "critical"
    | "warning"
    | "precaution"
    | "info"
    | "overall";
  /**
   * The icon to be displayed in the ScoreButton
   */
  icon?: React.ReactNode;
  /**
   * Whether the ScoreButton is active
   */
  active?: boolean;
  /**
   * The event handler of the ScoreButton
   */
  onClick: () => void;
}

interface ScoreButtonClass {
  name: string;
  boxStyleSubtle: string;
}

const buttonProps: Array<ScoreButtonClass> = [
  {
    name: "low",
    boxStyleSubtle: "border-scoring-low"
  },
  {
    name: "medium",
    boxStyleSubtle: "border-scoring-medium"
  },
  {
    name: "high",
    boxStyleSubtle: "border-scoring-high"
  },
  {
    name: "critical",
    boxStyleSubtle: "border-scoring-critical"
  },
  {
    name: "warning",
    boxStyleSubtle: "border-scoring-warning"
  },
  {
    name: "precaution",
    boxStyleSubtle: "border-scoring-precaution"
  },
  {
    name: "info",
    boxStyleSubtle: "border-scoring-info"
  },
  {
    name: "overall",
    boxStyleSubtle: "border-scoring-overall"
  }
];

function ScoreButton(props: Props) {
  const { message, variant, icon, active, onClick } = props;

  const fontStyle = `normal-case font-sans text-sm font-medium ${
    active ? "text-default-white" : "text-gray-10"
  }`;

  const [boxStyle, setBoxStyle] = useState<string>(
    "cursor-pointer rounded-full py-1 px-2 w-fit border-4"
  );

  const handleStyleChange = () => {
    const style = buttonProps.find((item) => item.name === variant);
    // active ? "bg-gray-7 " : "bg-gray-1 "
    if (style) {
      setBoxStyle(
        `${style.boxStyleSubtle} ${
          active ? "bg-gray-7 " : "bg-gray-1 "
        } cursor-pointer rounded-full py-1 px-2 w-fit border-4`
      );
    }
  };

  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [variant, active]);

  return (
    <Box
      className={boxStyle}
      display="flex"
      alignItems="center"
      onClick={onClick}
    >
      {icon && <Box className="mr-1">{icon}</Box>}
      <Typography className={fontStyle}>{message}</Typography>
    </Box>
  );
}

ScoreButton.defaultProps = {
  icon: null,
  active: false
};

export default ScoreButton;
