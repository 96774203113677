import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconClose } from "../Icons/Icons";

export interface Props {
  message: string | React.ReactNode;
  variant?: "classic" | "rainbow" | "gender";
  withBorder?: boolean;
  withPadding?: boolean;
  disabled?: boolean;
  withCloseFilter?: boolean;
  onClose?: (data: object) => void;
  data?: object;
  name?: string;
  onClick?: (event: any) => void;
}

function Tag(props: Props) {
  const {
    message,
    variant,
    withBorder,
    withPadding,
    disabled,
    withCloseFilter,
    onClose,
    data,
    name,
    onClick
  } = props;
  const [style, setStyle] = useState<string>("");

  const handleStyleChange = () => {
    let cssStyle =
      "w-fit gap-0.5 flex rounded-md bg-gray-1 px-1.5 border border-default-transparent stroke-gray-10 items-center whitespace-nowrap";

    if (withBorder === true) cssStyle = `${cssStyle} border border-gray-4`;
    if (withPadding) cssStyle = `${cssStyle} py-1`;
    if (disabled === true)
      cssStyle = `${cssStyle} text-gray-5 !border-default-transparent opacity-50`;

    setStyle(cssStyle ?? "");
  };

  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [withBorder, disabled]);

  const fontStyle = "normal-case font-sans text-gray-10 text-xs font-medium";

  if (variant === "rainbow") {
    return (
      <Box
        className="w-fit gap-0.5 flex rounded-md p-0.5 rounded-md"
        id="rainbow"
      >
        <Box className="rounded-md px-1.5 bg-default-white back">
          <Typography
            component="h1"
            className="normal-case font-sans text-gray-10 text-xs font-medium"
          >
            {message}
          </Typography>
        </Box>
      </Box>
    );
  }
  if (variant === "gender") {
    return (
      <Box
        className="w-fit gap-0.5 flex rounded-md p-0.5 rounded-md"
        id="gender"
      >
        <Box className="rounded-md px-1.5 bg-default-white back">
          <Typography
            component="h1"
            className="normal-case font-sans text-gray-10 text-xs font-medium"
          >
            {message}
          </Typography>
        </Box>
      </Box>
    );
  }
  if (variant === "classic") {
    return (
      <Box
        className={style}
        onClick={onClick}
        sx={{
          cursor: onClick ? "pointer" : "default"
        }}
      >
        <Typography className={fontStyle} component="span">
          {message}
        </Typography>
        {withCloseFilter && (
          <Box
            className="cursor-pointer"
            onClick={() => onClose({ name, data })}
          >
            <IconClose width={17} />
          </Box>
        )}
      </Box>
    );
  }
}

Tag.defaultProps = {
  variant: "classic",
  withBorder: false,
  withPadding: true,
  disabled: false,
  withCloseFilter: false,
  onClose: () => {
    /*  */
  },
  data: {},
  name: "",
  onClick: () => {
    /*  */
  }
};

export default Tag;
