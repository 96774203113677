import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNotification } from "../notifications/notificationsSlice";
import { Users } from "./models/users.interface";
import usersService from "./users.service";

interface AsyncState {
  isLoadingPatchUser: boolean;
  isLoadingAddUser: boolean;
  isLoading: boolean;
  isSuccessful: boolean;
  isError: boolean;
}

interface UsersState extends AsyncState {
  users?: Users | null;
}

type GetUsers = {
  accessToken: string;
};

type AddUser = {
  accessToken: string;
  user: {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
  };
};

type UpdateUser = {
  accessToken: string;
  userId: string | undefined;
  user: {
    first_name?: string;
    last_name?: string;
    email?: string;
    role?: string;
  };
};

type DeleteUser = {
  accessToken: string;
  userId: string;
};

const initialState: UsersState = {
  isLoadingPatchUser: false,
  isLoadingAddUser: false,
  isLoading: false,
  isSuccessful: false,
  isError: false,
  users: null
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (data: GetUsers, thunkAPI) => {
    try {
      const { accessToken } = data;
      return await usersService.getUsers(accessToken);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const postUser = createAsyncThunk(
  "users/postUser",
  async (data: AddUser, thunkAPI) => {
    try {
      const { accessToken, user } = data;
      const response = await usersService.postUser(accessToken, user);
      if (response) {
        thunkAPI.dispatch(
          addNotification({
            message: "User added successfully",
            type: "success"
          })
        );
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          message: "User add failed",
          type: "error"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const patchUser = createAsyncThunk(
  "users/patchUser",
  async (data: UpdateUser, thunkAPI) => {
    try {
      const { accessToken, user, userId } = data;
      const response = await usersService.patchUser(accessToken, userId, user);
      if (response) {
        thunkAPI.dispatch(
          addNotification({
            message: "User updated successfully",
            type: "success"
          })
        );
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          message: "User update failed",
          type: "error"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (data: DeleteUser, thunkAPI) => {
    try {
      const { accessToken, userId } = data;
      const response = await usersService.deleteUser(accessToken, userId);
      if (response) {
        thunkAPI.dispatch(
          addNotification({
            message: "User deleted successfully",
            type: "success"
          })
        );
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          message: "User delete failed",
          type: "error"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.isSuccessful = false;
        state.isError = false;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessful = true;
        state.isError = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.isLoading = false;
        state.isSuccessful = false;
        state.isError = true;
        state.users = null;
      })
      .addCase(postUser.pending, (state) => {
        state.isLoadingAddUser = true;
        state.isSuccessful = false;
        state.isError = false;
      })
      .addCase(postUser.fulfilled, (state) => {
        state.isLoadingAddUser = false;
        state.isSuccessful = true;
        state.isError = false;
      })
      .addCase(postUser.rejected, (state) => {
        state.isLoadingAddUser = false;
        state.isSuccessful = false;
        state.isError = true;
      })
      .addCase(patchUser.pending, (state) => {
        state.isLoadingPatchUser = true;
        state.isSuccessful = false;
        state.isError = false;
      })
      .addCase(patchUser.fulfilled, (state) => {
        state.isLoadingPatchUser = false;
        state.isSuccessful = true;
        state.isError = false;
      })
      .addCase(patchUser.rejected, (state) => {
        state.isLoadingPatchUser = false;
        state.isSuccessful = false;
        state.isError = true;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.isSuccessful = false;
        state.isError = false;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccessful = true;
        state.isError = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isLoading = false;
        state.isSuccessful = false;
        state.isError = true;
      });
  }
});

export default usersSlice.reducer;
