import { getUserType } from "common/utils/roles/getUserUtil";
import { getPermissions, ROLES } from "common/utils/roles/roles";

const checkRoleKey = (roleKey) => {
  switch (roleKey) {
    case "haistack_admin":
      return ROLES.HAISTACK_ADMIN;
    case "firm_admin":
      return ROLES.FIRM_ADMIN;
    case "recruiter":
      return ROLES.RECRUITER;
    case "test_driver":
      return ROLES.TEST_DRIVER;
    default:
      return ROLES.RECRUITER;
  }
};

const getUserPermissions = (roleKey) => {
  return getPermissions[roleKey];
};

export const setupLogin = (roleKey) => {
  return {
    user: getUserType(checkRoleKey(roleKey)),
    permissions: getUserPermissions(checkRoleKey(roleKey))
  };
};
