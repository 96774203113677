/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import { useAuth0 } from "@auth0/auth0-react";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  createTheme,
  Grid,
  Popper,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import { colors } from "common/scss/themes";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { setFilters } from "services/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "stateManagement/hooks";
import Badge from "../Badge";
import Button from "../Button";
import Download from "../Download";
import SearchInput from "../FormComponents/SearchInput";
import IconButton from "../IconButton";
import { IconArrowRight, IconFilter } from "../Icons/Icons";

const themePagination = createTheme({
  typography: {
    fontFamily: "Noto Sans, sans-serif"
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Noto Sans, sans-serif !important",
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "1rem"
        }
      }
    }
  }
});

export interface Props {
  label?: string;
  numberOfResults: number;
  currentPage: number;
  rowsPerPage: number;
  changePage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  rowsPerPageOptions?: number[];
  withFilters?: boolean;
  withSearch?: boolean;
  withPadding?: boolean;
  toggleFiltersVisible?: () => void;
  download?: boolean;
  dataTable?: any;
  transform?: (data: any[]) => any[];
  tableRef?: any;
  shareButton?: boolean;
  shareAction?: () => void;
  optionsDropdown?: any[];
  filtersDropdown?: object;
  setFiltersDropdown?: (filters: any) => void;
  showPagination?: boolean;
  loading?: boolean;
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  };

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].label}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (Object.prototype.hasOwnProperty.call(child, "group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: any) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0
    }
  }
});
function ResultsBar(props: Props) {
  const {
    label,
    numberOfResults,
    currentPage,
    changePage,
    setRowsPerPage,
    rowsPerPageOptions = [10, 25, 50, 100],
    withFilters,
    withSearch,
    withPadding,
    toggleFiltersVisible,
    filtersVisible,
    rowsPerPage,
    download,
    dataTable,
    transform,
    tableRef,
    shareButton,
    shareAction,
    optionsDropdown,
    filtersDropdown,
    loading,
    setFiltersDropdown = () => {
      /*  */
    },
    showPagination
  } = props;
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [valueOffice, setValueOffice] = useState([]);
  const filtersRedux = useAppSelector((state) => state.filters.filters);

  const { isLoadingTargets } = useAppSelector((state) => state.job);
  const { canDownloadReports } = useAppSelector((state) => state.permissions);

  useEffect(() => {
    setFiltersDropdown({ ...filtersRedux });
  }, [filtersRedux]);

  const addFiltersData = (key: any, data: any) => {
    const obj = filtersDropdown;
    if (data.length > 0) {
      obj[key] = data;
    } else {
      delete obj[key];
    }
    setFiltersDropdown({ ...obj });
    dispatch(setFilters({ ...obj }));
  };

  useEffect(() => {
    if (!_.isEmpty(valueOffice)) {
      const value = valueOffice.map((item: any) => item.value);
      addFiltersData("locations", value);
    } else {
      addFiltersData("locations", []);
    }
  }, [valueOffice]);

  const changeInnerPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const table = tableRef;
    if (table) {
      table.current.scrollTop = 0;
    }

    changePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changePage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Grid
      container
      alignItems="center" /* className="py-1 px-6 bg-default-white" */
      className={`py-1 bg-default-white ${withPadding && "px-6"}`}
    >
      <Grid item xs={4} py={1}>
        <Box gap={1} className="flex items-center">
          <Typography
            component="span"
            className="font-sans text-lg font-semibold text-gray-10 normal-case"
          >
            {label}
          </Typography>
          {numberOfResults && !loading && (
            <Badge message={numberOfResults} variant="neutral" type="Subtle" />
          )}
        </Box>
      </Grid>

      <Grid item xs py={1}>
        <Grid
          container
          justifyContent="flex-end"
          gap={0.5}
          className="flex items-center"
        >
          <ThemeProvider theme={themePagination}>
            {showPagination && !loading && (
              <TablePagination
                sx={{
                  "& .MuiTablePagination-selectLabel": {
                    fontWeight: 500,
                    fontSize: "0.75rem",
                    lineHeight: "1rem"
                  },
                  "& .MuiTablePagination-displayedRows": {
                    fontWeight: 500,
                    fontSize: "0.75rem",
                    lineHeight: "1rem"
                  },
                  "& .MuiTablePagination-select": {
                    fontWeight: 500,
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    display: "flex",
                    alignItems: "end"
                  },
                  "& .MuiTablePagination-menuItem": {
                    fontFamily: "Noto Sans, sans-serif !important",
                    fontWeight: 500,
                    fontSize: "1px",
                    lineHeight: "1rem"
                  }
                }}
                slotProps={{
                  select: {
                    sx: {
                      ".MuiMenuItem-root": {
                        fontSize: "0.75rem" // Adjust font size as needed
                      }
                    }
                  }
                }}
                component="div"
                count={numberOfResults}
                page={currentPage - 1}
                onPageChange={changeInnerPage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            )}
          </ThemeProvider>
          {download && canDownloadReports && (
            <Grid item>
              <Download
                data={dataTable}
                transform={transform}
                buttonSize="sm"
                label="Download"
                disabled={isLoadingTargets}
              />
            </Grid>
          )}
          {shareButton && isAuthenticated && (
            <Grid item>
              <Button
                id="share"
                message="Share"
                variant="secondary"
                size="sm"
                onClick={shareAction}
              />
            </Grid>
          )}
          {withFilters && (
            <Grid item>
              <Box display="flex" alignItems="center" gap={1}>
                {withSearch && <SearchInput placeholder="Search..." />}
                {/* <Button
                id="advanced-search"
                  message="Advanced Search"
                  variant="secondary"
                  size="sm"
                  onClick={toggleFiltersVisible}
                /> */}
                <IconButton
                  id="advanced-search"
                  icon={
                    filtersVisible ? (
                      <IconArrowRight width={28} height={28} />
                    ) : (
                      <IconFilter width={28} height={28} />
                    )
                  }
                  size="sm"
                  variant="secondary"
                  onClick={toggleFiltersVisible}
                />
              </Box>
            </Grid>
          )}

          {/* End Pagination */}

          {/* Start Export */}
          {/*           <Grid item>
            <Button
            id="export"
              message={
                <Box display="flex" alignItems="center">
                  <Box className="mr-1">
                    <IconDownload width={18} height={20} />
                  </Box>
                  <Box>
                    <Typography
                      component="span"
                      className="normal-case font-sans text-sm font-semibold text-default-white"
                    >
                      Export
                    </Typography>
                  </Box>
                </Box>
              }
              variant="primary"
              size="sm"
            />
          </Grid> */}
          {/* End Export */}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        gap={0.5}
        className="flex items-center"
      >
        {!_.isEmpty(optionsDropdown) && (
          <Box width={390}>
            {/*  <Select
              options={optionsDropdown}
              selectLabel="Offices"
              value={valueOffice}
              onChange={(e: any) => setValueOffice(e.target.value)}
              disabled={isLoadingTargets}
            /> */}
            <Autocomplete
              multiple
              id="Offices"
              options={optionsDropdown}
              disableCloseOnSelect
              disableListWrap
              size="small"
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              renderOption={(renderProps, option, state) =>
                [renderProps, option, state.index] as React.ReactNode
              }
              onChange={(e, value) => {
                setValueOffice(value);
              }}
              classes={{
                option:
                  "h-[29px] min-h-[29px] p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1",
                tag: "h-[24px] min-h-[24px] p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1"
              }}
              value={valueOffice}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Select Office Locations"
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: "7px 12px",
                      fontSize: "14px",
                      fontFamily: "Noto Sans, sans-serif",
                      lineHeight: "1.25rem",
                      color: "#1a202c",
                      backgroundColor: "#fff"
                    },
                    "& .MuiInputBase-root": {
                      border: `none`
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.gray[4],
                        borderRadius: "0.375rem"
                      },
                      "&:hover fieldset": {
                        borderColor: colors.gray[5]
                      },
                      "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: colors.primary[4]
                      },
                      "&.Mui-disabled fieldset": {
                        borderColor: colors.gray[4],
                        backgroundColor: colors.gray[1]
                      },
                      "&.Mui-error fieldset": {
                        borderColor: colors.default.error[7]
                      },
                      "&.Mui-error:hover fieldset": {
                        borderColor: colors.default.error[7]
                      },
                      "&.Mui-error.Mui-focused fieldset": {
                        borderColor: colors.default.error[7]
                      },
                      "&.Mui-error.Mui-disabled fieldset": {
                        borderColor: colors.gray[4],
                        backgroundColor: colors.gray[1]
                      },
                      "&.MuiInputLabel-root": {
                        color: colors.primary[4]
                      }
                    }
                  }}
                />
              )}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

ResultsBar.defaultProps = {
  label: "Search Results",
  withFilters: false,
  withSearch: true,
  withPadding: false,
  download: false,
  dataTable: [],
  rowsPerPageOptions: [10, 25, 50, 100],
  toggleFiltersVisible: () => {
    /*  */
  },
  transform: () => {
    /*  */
  },
  tableRef: null,
  shareButton: false,
  shareAction: () => {
    /*  */
  },
  optionsDropdown: [],
  filtersDropdown: [],
  setFiltersDropdown: () => {
    /*  */
  },
  showPagination: true,
  loading: false
};

export default ResultsBar;
