import { Alert as MuiAlert } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import {
  IconAlertCircle,
  IconCheck,
  IconCloseCircle,
  IconInfoCircle
} from "../Icons/Icons";

export interface Props {
  message: string;
  variant: "success" | "warning" | "danger" | "info";
  placement: "topRight" | "bottomRight";
  onClose?: () => void;
}

interface AlertProps {
  name: string;
  style: string;
  icon: ReactNode;
}

const alertProps: Array<AlertProps> = [
  {
    name: "success",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-success-9 bg-default-success-3 border-default-success-7 fixed w-2/5 stroke-default-success-9",
    icon: <IconCheck width={20} height={20} />
  },
  {
    name: "warning",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-warning-8 bg-default-warning-2 border-default-warning-6 fixed w-2/5 stroke-default-warning-9",
    icon: <IconAlertCircle width={20} height={20} />
  },
  {
    name: "danger",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-error-9 bg-default-error-3 border-default-error-7 fixed w-2/5 stroke-default-error-9",
    icon: <IconCloseCircle width={20} height={20} />
  },
  {
    name: "info",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-info-9 bg-default-info-3 border-default-info-7 fixed w-2/5 stroke-default-info-9",
    icon: <IconInfoCircle width={20} height={20} />
  }
];

function Alert(props: Props) {
  const { message, variant, placement, onClose } = props;

  const [style, setStyle] = useState<string>("");
  const attributes: AlertProps | undefined = alertProps.find((classes) => {
    return classes.name === variant;
  });

  const handleStyleChange = () => {
    let cssStyle = attributes?.style;

    if (placement === "topRight")
      cssStyle = `${attributes?.style} top-5 right-5`;
    if (placement === "bottomRight")
      cssStyle = `${attributes?.style} bottom-5 right-5`;

    setStyle(cssStyle ?? "");
  };

  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [variant, placement]);

  return (
    <MuiAlert className={style} icon={attributes?.icon} onClose={onClose}>
      {message}
    </MuiAlert>
  );
}

Alert.defaultProps = {
  onClose: () => {
    /* parent should handle this */
  }
};

export default Alert;
