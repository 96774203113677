/* eslint-disable react/jsx-props-no-spreading */
import { Box, FormHelperText, InputLabel, Typography } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { colors } from "common/scss/themes";
import { ErrorMessage, useField } from "formik";
import moment from "moment";
import React from "react";

function DatePicker({ id = "", name = "", label = "", ...props }) {
  const [field] = useField(name);
  // TODO : Style error border
  const handleDateChange = (date: any) => {
    const formattedDate = date ? date.toISOString() : null;
    field.onChange({ target: { name, value: formattedDate } });
  };

  return (
    <Box className="w-full">
      {label && (
        <InputLabel
          htmlFor={id}
          classes={{
            root: "block font-sans text-sm font-medium text-gray-10"
          }}
          className="mb-1"
        >
          {label}
        </InputLabel>
      )}
      <Box>
        <MuiDatePicker
          {...props}
          value={field.value ? moment(field.value) : null}
          onChange={handleDateChange}
          sx={{
            width: "100%",
            "& .MuiInputBase-input": {
              padding: "7px 12px",
              fontSize: "14px",
              fontFamily: "Noto Sans, sans-serif",
              lineHeight: "1.25rem",
              color: "#1a202c",
              backgroundColor: "#fff",
              borderColor: colors.default.error[7]
            },
            "& .MuiInputBase-root": {
              border: `none`
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: colors.gray[4],
                borderRadius: "0.375rem"
              },
              "&:hover fieldset": {
                borderColor: colors.gray[5]
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px",
                borderColor: colors.primary[4]
              },
              "&.Mui-disabled fieldset": {
                borderColor: colors.gray[8],
                backgroundColor: colors.gray[1],
                opacity: 0.2
              },
              "&.Mui-error fieldset": {
                borderColor: colors.default.error[7]
              },
              "&.Mui-error:hover fieldset": {
                borderColor: colors.default.error[7]
              },
              "&.Mui-error.Mui-focused fieldset": {
                borderColor: colors.default.error[7]
              },
              "&.Mui-error.Mui-disabled fieldset": {
                borderColor: colors.gray[4],
                backgroundColor: colors.gray[1]
              }
            }
          }}
        />
        <ErrorMessage
          component={FormHelperText}
          name={name}
          render={(msg) => (
            <Typography
              component="span"
              className="px-2 py-1 font-sans text-xs font-medium text-default-error-9"
            >
              {msg}
            </Typography>
          )}
        />
      </Box>
    </Box>
  );
}

export default DatePicker;
