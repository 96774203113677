import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNotification } from "../notifications/notificationsSlice";
import jobsService from "./jobs.service";
import { Jobs, PostJob } from "./models/jobs.interface";

interface AsyncState {
  isLoadingNewJob: boolean;
  isLoading: boolean;
  isSuccessful: boolean;
  isError: boolean;
  error: string | null;
}

interface JobsState extends AsyncState {
  jobsWithId?: Jobs[] | null;
  meta?: {
    pages: number;
    count: number;
  };
}

const initialState: JobsState = {
  isLoadingNewJob: false,
  isLoading: false,
  isSuccessful: false,
  isError: false,
  error: null,
  jobsWithId: null,
  meta: {
    pages: 0,
    count: 0
  }
};

type PostJobData = {
  accessToken: string;
  job: PostJob;
};

export const postJob = createAsyncThunk(
  "jobs/postJob",
  async (data: PostJobData, thunkAPI) => {
    try {
      const { accessToken, job } = data;
      const response = await jobsService.postJob(accessToken, job);
      thunkAPI.dispatch(
        addNotification({
          type: "success",
          message: "Job created successfully"
        })
      );
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          type: "danger",
          message: "Error creating job"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

type PatchJobData = {
  accessToken: string;
  jobId: number;
  job: PostJob;
};

export const patchJob = createAsyncThunk(
  "jobs/patchJob",
  async (data: PatchJobData, thunkAPI) => {
    try {
      const { accessToken, jobId, job } = data;
      const response = await jobsService.patchJob(accessToken, jobId, job);
      thunkAPI.dispatch(
        addNotification({
          type: "success",
          message: "Job updated successfully"
        })
      );
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          type: "danger",
          message: "Error updating job"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getJobsWithId = createAsyncThunk(
  "jobs/getJobsWithId",
  async (data: { accessToken: string; jobId: number }, thunkAPI) => {
    try {
      const { accessToken, jobId } = data;
      return await jobsService.getJobsWithId(accessToken, jobId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const patchJobsWithId = createAsyncThunk(
  "jobs/patchJobsWithId",
  async (
    data: { accessToken: string; jobId: number; jobsIds: number[] },
    thunkAPI
  ) => {
    try {
      const { accessToken, jobId, jobsIds } = data;
      const response = await jobsService.patchJobsWithId(
        accessToken,
        jobId,
        jobsIds
      );

      if (response.job_statuses && response.job_statuses.length > 0) {
        thunkAPI.dispatch(
          addNotification({
            type: "success",
            message: "Jobs added successfully"
          })
        );
      }

      return response;
    } catch (error) {
      thunkAPI.dispatch(
        addNotification({
          type: "danger",
          message: "Error adding jobs"
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    resetJobs: (state) => {
      state.isLoading = false;
      state.isSuccessful = false;
      state.isError = false;
      state.error = null;
      state.jobs = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postJob.pending, (state) => {
        state.isLoadingNewJob = true;
      })
      .addCase(postJob.fulfilled, (state) => {
        state.isLoadingNewJob = false;
      })
      .addCase(postJob.rejected, (state) => {
        state.isLoadingNewJob = false;
      })
      .addCase(patchJob.pending, (state) => {
        state.isLoadingNewJob = true;
      })
      .addCase(patchJob.fulfilled, (state) => {
        state.isLoadingNewJob = false;
      })
      .addCase(patchJob.rejected, (state) => {
        state.isLoadingNewJob = false;
      })
      .addCase(getJobsWithId.pending, (state) => {
        state.isLoading = true;
        state.isSuccessful = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(getJobsWithId.fulfilled, (state, action) => {
        state.jobsWithId = action.payload?.jobs;
        state.isLoading = false;
        state.isSuccessful = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getJobsWithId.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccessful = false;
        state.isError = true;
        state.error = action.error?.message || "Unknown Error";
        state.jobsWithId = null;
      });
  }
});

export const { resetJobs } = jobsSlice.actions;
export default jobsSlice.reducer;
