import { Avatar as MuiAvatar } from "@mui/material";
import React, { ReactNode } from "react";

export interface Props {
  image?: string;
  name: string | undefined;
  size: "extraSmall" | "small" | "medium" | "large";
  children?: ReactNode;
}

interface AvatarProps {
  name: string;
  style: string;
  fontSize: string;
}
const avatarProps: Array<AvatarProps> = [
  {
    name: "extraSmall",
    style: "w-[24px] h-[24px] bg-gray-3 text-gray-9",
    fontSize: "text-xs"
  },
  {
    name: "small",
    style: "w-[32px] h-[32px] bg-gray-3 text-gray-9",
    fontSize: "text-xs"
  },
  {
    name: "medium",
    style: "w-[40px] h-[40px] bg-gray-3 text-gray-9",
    fontSize: "text-sm"
  },
  {
    name: "large",
    style: "w-[56px] h-[56px] bg-gray-3 text-gray-9",
    fontSize: "text-base"
  }
];
function Avatar(props: Props) {
  const { image, name, size = "medium", children } = props;

  const style = avatarProps.find((item) => item.name === size);

  return children ? (
    <MuiAvatar className={style?.style} alt={name}>
      {children}
    </MuiAvatar>
  ) : (
    <MuiAvatar className={style?.style} src={image} alt={name} />
  );
}

Avatar.defaultProps = {
  image: "",
  children: null
};

export default Avatar;
