import {
  Autocomplete,
  Box,
  CircularProgress,
  InputLabel,
  TextField
} from "@mui/material";
import React from "react";

import { colors } from "common/scss/themes";

interface Props {
  loading?: boolean;
  label?: string;
  placeholder?: string;
  id?: string;
  sx?: React.CSSProperties;
  options: Array<any>;
  value: any;
  onChange: () => void;
  inputValue: string;
  onInputChange: () => void;
  renderOption?: () => void;
  renderInput?: () => void;
  getOptionLabel: () => void;
  disabled?: boolean;
  multiple?: boolean;
}
function CustomAutocomplete(props: Props) {
  const {
    loading,
    label,
    placeholder,
    id,
    sx,
    options,
    value,
    onChange,
    inputValue,
    onInputChange,
    renderOption,
    getOptionLabel,
    disabled,
    multiple
  } = props;

  return (
    <Box className="w-full">
      {label && (
        <InputLabel
          htmlFor={id}
          classes={{
            root: "block font-sans text-sm font-medium text-gray-10"
          }}
          className="mb-1"
        >
          {label}
        </InputLabel>
      )}
      <Autocomplete
        id={id}
        options={options}
        value={value}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        multiple={multiple}
        renderInput={(params: any) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={placeholder}
            fullWidth
            inputProps={{
              ...params.inputProps
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            sx={{
              "& .MuiInputBase-input": {
                padding: "7px 12px",
                fontSize: "14px",
                fontFamily: "Noto Sans, sans-serif",
                lineHeight: "1.25rem",
                color: "#1a202c",
                backgroundColor: "#fff"
              },
              "& .MuiInputBase-root": {
                border: `none`
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: colors.gray[4],
                  borderRadius: "0.375rem"
                },
                "&:hover fieldset": {
                  borderColor: colors.gray[5]
                },
                "&.Mui-focused fieldset": {
                  borderWidth: "1px",
                  borderColor: colors.primary[4]
                },
                "&.Mui-disabled fieldset": {
                  borderColor: colors.gray[4],
                  backgroundColor: colors.gray[1]
                },
                "&.Mui-error fieldset": {
                  borderColor: colors.default.error[7]
                },
                "&.Mui-error:hover fieldset": {
                  borderColor: colors.default.error[7]
                },
                "&.Mui-error.Mui-focused fieldset": {
                  borderColor: colors.default.error[7]
                },
                "&.Mui-error.Mui-disabled fieldset": {
                  borderColor: colors.gray[4],
                  backgroundColor: colors.gray[1]
                }
              }
            }}
          />
        )}
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            padding: "0px"
          },
          ...sx
        }}
      />
    </Box>
  );
}

CustomAutocomplete.defaultProps = {
  loading: false,
  label: "",
  placeholder: "",
  id: "",
  sx: {},
  renderOption: () => {
    /*  */
  },
  renderInput: () => {
    /*  */
  },
  disabled: false,
  multiple: false
};

export default CustomAutocomplete;
