import { callExternalApi } from "../external-api.service";

import { PostJob } from "./models/jobs.interface";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

type ResponsePostJob = {
  job: PostJob;
};

const postJob = async (
  accessToken: string,
  job: PostJob
): Promise<ResponsePostJob> => {
  const config = {
    url: `${apiServerUrl}/jobs`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: job
  };

  const { data, error } = await callExternalApi({ config });
  if (data) {
    return data;
  }
  throw new Error(`Jobs Error: ${error?.message}` || "Unknown Error");
};

const patchJob = async (
  accessToken: string,
  jobId: number,
  job: PostJob
): Promise<ResponsePostJob> => {
  const config = {
    url: `${apiServerUrl}/jobs/${jobId}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: job
  };

  const { data, error } = await callExternalApi({ config });
  if (data) {
    return data;
  }
  throw new Error(`Jobs Error: ${error?.message}` || "Unknown Error");
};

const getJobsWithId = async (
  accessToken: string,
  jobId: number
): Promise<ResponsePostJob> => {
  const config = {
    url: `${apiServerUrl}/api/v1/targets/${jobId}/jobs`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });
  if (data) {
    return data;
  }
  throw new Error(`Jobs Error: ${error?.message}` || "Unknown Error");
};

type ResponsePatchJobsWithId = {
  job_statuses: any[];
};

const patchJobsWithId = async (
  accessToken: string,
  jobId: number,
  jobsIds: number[]
): Promise<ResponsePatchJobsWithId> => {
  const config = {
    url: `${apiServerUrl}/api/v1/targets/${jobId}/jobs/status`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      job_ids: jobsIds
    }
  };

  const { data, error } = await callExternalApi({ config });
  if (data) {
    return data;
  }
  throw new Error(`Jobs Error: ${error?.message}` || "Unknown Error");
};

const jobsService = {
  postJob,
  patchJob,
  getJobsWithId,
  patchJobsWithId
};

export default jobsService;
