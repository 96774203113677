import { FormControlLabel, Radio as MuiRadio } from "@mui/material";
import { colors } from "common/scss/themes";
import React from "react";

export interface Props {
  readonly label?: string;
  readonly labelPlacement?: "start" | "end" | "top" | "bottom";
  readonly checked?: boolean;
  readonly value?: string | number;
  readonly name?: string;
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
function Radio(props: Props) {
  const { label, labelPlacement, value, checked, onChange, name } = props;

  return (
    <FormControlLabel
      control={
        <MuiRadio
          {...props}
          checked={checked}
          value={value}
          name={name}
          disableRipple
          onChange={onChange}
          sx={{
            color: colors.gray[4],
            margin: "5px",
            padding: 0,
            "&.Mui-checked": {
              color: colors.primary[8]
            },
            "&.Mui-disabled": {
              color: colors.gray[5]
            },
            "&.Mui-checked:active": {
              boxShadow: `0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px ${colors.primary.shadow}`
            }
          }}
        />
      }
      labelPlacement={labelPlacement}
      label={label}
      classes={{
        label: "font-sans text-sm font-medium text-gray-10"
      }}
    />
  );
}

Radio.defaultProps = {
  label: "",
  labelPlacement: "end",
  value: "",
  name: "",
  checked: false,
  onChange: () => {
    /* parent component should handle this */
  }
};

export default Radio;
