import { Box, Tab as MuiTab, Tabs } from "@mui/material";
import { colors } from "common/scss/themes";
import React, {
  ReactElement,
  SetStateAction,
  SyntheticEvent,
  useState
} from "react";

export interface Props {
  tabs: {
    label: string | ReactElement;
    index: number;
    children: ReactElement;
  }[];
  setCurrent?: (value: SetStateAction<number>) => void;
  widthTab?: string;
}
function Tab(props: Props) {
  const { tabs, setCurrent, widthTab } = props;
  const [value, setValue] = useState(0);

  const handleChange = (e: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (setCurrent) setCurrent(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: colors.primary[8]
          }
        }}
      >
        {tabs.map((tab) => (
          <MuiTab
            key={tab.index}
            label={tab.label}
            disableRipple
            value={tab.index}
            sx={{
              "&.Mui-selected": {
                color: colors.primary[8],
                borderBottom: `2px solid ${colors.primary[8]}`,
                stroke: colors.primary[8]
              }
            }}
            className={
              `font-sans text-sm font-medium text-gray-10 normal-case border-solid border-b-2 border-default-transparent stroke-gray-10 ` +
              `hover:border-primary-8 ${widthTab}`
            }
          />
        ))}
      </Tabs>

      {tabs.map(
        (tab) =>
          tab.children &&
          value === tab.index && <Box key={tab.index}>{tab.children}</Box>
      )}
    </Box>
  );
}

Tab.defaultProps = {
  setCurrent: () => {
    /* parent component should handle this */
  },
  widthTab: ""
};

export default Tab;
