import { createSlice } from "@reduxjs/toolkit";
import { PERMISSIONS } from "common/utils/roles/roles";

interface AsyncState {
  isLoading: boolean;
  isSuccessful: boolean;
  isError: boolean;
}

interface PermissionsState extends AsyncState {
  canImpersonate?: boolean;
  canManageUsers?: boolean;
  shouldBlurText?: boolean;
  shouldShowInProd?: boolean;
  canDownloadReports?: boolean;
  email?: string;
  userId?: string;
  firmId?: string;
  firmName?: string;
  role?: string;
  expirationDate?: string;
}

const initialState: PermissionsState = {
  isLoading: false,
  isSuccessful: false,
  isError: false,
  canImpersonate: false,
  canManageUsers: false,
  shouldBlurText: false,
  shouldShowInProd: false,
  canDownloadReports: false,
  email: "",
  userId: "",
  firmId: "",
  firmName: "",
  role: "",
  expirationDate: ""
};

const hasPermissions = (permissions: any, permissionNames: any) => {
  if (!permissions) {
    return false;
  }
  if (typeof permissionNames === "number") {
    return permissions.includes?.(permissionNames);
  }
  if (Array.isArray(permissionNames)) {
    return permissions.some((permissionName: any) =>
      Boolean(permissionNames.includes?.(permissionName))
    );
  }
  return false;
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    resetPermissions: (state) => {
      state.isLoading = false;
      state.isSuccessful = false;
      state.isError = false;
      state.canImpersonate = false;
      state.canManageUsers = false;
      state.shouldBlurText = false;
      state.shouldShowInProd = false;
      state.canDownloadReports = false;
      state.email = "";
      state.userId = "";
      state.firmId = "";
      state.firmName = "";
      state.role = "";
      state.expirationDate = "";
    },
    setLoginPermissions: (state, action) => {
      const {
        permissions,
        email,
        userId,
        firmId,
        firmName,
        role,
        expirationDate
      } = action.payload;
      return {
        ...state,
        canImpersonate: hasPermissions(
          permissions,
          PERMISSIONS.CAN_VIEW_IMPERSONATE
        ),
        canManageUsers: hasPermissions(
          permissions,
          PERMISSIONS.CAN_MANAGE_USERS
        ),
        shouldBlurText: hasPermissions(
          permissions,
          PERMISSIONS.SHOULD_BLUR_TEXT
        ),
        shouldShowInProd: process.env.ENV_LABEL !== "production",
        canDownloadReports: hasPermissions(
          permissions,
          PERMISSIONS.CAN_DOWNLOAD_REPORTS
        ),
        email,
        userId,
        firmId,
        firmName,
        role,
        expirationDate
      };
    }
  }
});

export const { resetPermissions, setLoginPermissions } =
  permissionsSlice.actions;
export default permissionsSlice.reducer;
