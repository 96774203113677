const nameToLabel = (name) => {
  switch (name) {
    case "first_name":
      return "First Name";
    case "last_name":
      return "Last Name";
    case "locations":
      return "Locations";
    case "job_titles":
      return "Job Titles";
    case "employers":
      return "Employers";
    case "practice_areas":
      return "Specialties";
    case "schools_law":
      return "Law Schools";
    case "schools_undergrad":
      return "Undergrad Schools";
    case "specialties":
      return "Specialties";
    case "gender":
      return "Genders";
    case "demographic":
      return "Demographics";
    case "jd_years":
      return "JD Years";
    default:
      return name;
  }
};
export default nameToLabel;
