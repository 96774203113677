import axios from "axios";
import { APP_ROUTES } from "common/constants/ROUTES";

export const callExternalApi = async (options: any) => {
  try {
    const response = await axios(options.config);
    const { data } = response;
    return {
      data,
      error: null
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      const { response } = axiosError;
      let message = "http request failed";

      if (response?.status && response.status === 404) {
        localStorage.setItem(
          "error",
          JSON.stringify({
            status: response.status,
            statusText: response.statusText,
            responseUrl: response.request?.responseURL,
            payload: response.config?.data
          })
        );
        window.location.replace(APP_ROUTES.NOT_FOUND);
      }

      message =
        axiosError?.response?.data?.message ??
        response?.statusText ??
        axiosError?.message ??
        response?.data?.message ??
        message;
      return {
        data: null,
        error: {
          message
        }
      };
    }

    return {
      data: null,
      error: {
        message: error.message
      }
    };
  }
};
