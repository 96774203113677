import { Box, Typography } from "@mui/material";
import React from "react";
import nameToLabel from "../../utils/filters-utils/name-to-label";
import { IconClose } from "../Icons/Icons";
import Tag from "../Tag";
import Tooltip from "../Tooltip";

export interface Props {
  message: string;
  onCloseAll?: (e: any) => void;
  onCloseFilter?: () => void;
  filters?: Array<{ label: string }>;
  withCloseFilter?: boolean;
  withCloseAll?: boolean;
}

function SegmentedFilter(props: Props) {
  const {
    message,
    onCloseAll,
    onCloseFilter,
    filters,
    withCloseFilter,
    withCloseAll
  } = props;

  const style =
    "py-2 px-3 flex w-fit bg-[#F4EDE2] gap-2 rounded stroke-gray-10 items-center";

  return (
    <Box className={style}>
      <Typography
        component="span"
        className="capitalize font-sans text-xs font-semibold text-gray-10"
      >
        {nameToLabel(message)}
      </Typography>
      {filters && filters.length > 3 ? (
        <>
          <Tag
            name={message}
            data={filters[0]}
            message={filters[0].label}
            withCloseFilter={withCloseFilter}
            onClose={onCloseFilter}
          />
          <Tag
            name={message}
            data={filters[1]}
            message={filters[1].label}
            withCloseFilter={withCloseFilter}
            onClose={onCloseFilter}
          />
          <Tag
            name={message}
            data={filters[2]}
            message={filters[2].label}
            withCloseFilter={withCloseFilter}
            onClose={onCloseFilter}
          />
          <Tooltip
            message={
              <Box display="flex" flexDirection="column" gap={1}>
                {filters.slice(3).map((filter) => (
                  <Box key={filter.label}>
                    <Tag
                      name={message}
                      data={filter}
                      message={filter.label}
                      withCloseFilter={withCloseFilter}
                      onClose={onCloseFilter}
                    />
                  </Box>
                ))}
              </Box>
            }
          >
            <Box className="cursor-pointer">
              <Tag message={`+${filters.length - 3}`} />
            </Box>
          </Tooltip>
        </>
      ) : (
        filters?.map((filter) => (
          <Tag
            name={message}
            data={filter}
            message={filter.label}
            withCloseFilter={withCloseFilter}
            onClose={onCloseFilter}
            key={filter.label}
          />
        ))
      )}
      {withCloseAll && (
        <Box
          className="cursor-pointer"
          onClick={() => onCloseAll?.({ name: message })}
        >
          <IconClose width={17} />
        </Box>
      )}
    </Box>
  );
}

SegmentedFilter.defaultProps = {
  filters: [],
  withCloseFilter: false,
  withCloseAll: false,
  onCloseAll: () => {
    /* parent component should handle this */
  },
  onCloseFilter: () => {
    /* parent component should handle this */
  }
};

export default SegmentedFilter;
