import { Box, Button as MuiButton } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

export interface Props {
  message: string | ReactElement;
  variant?: "start" | "middle" | "end";
  size?: "sm" | "bg";
  disabled?: boolean;
  onClick?: () => void;
}

interface SegmentedControlClass {
  name: string;
  style: string;
}

const segmentedControlProps: Array<SegmentedControlClass> = [
  {
    name: "start",
    style:
      "min-w-fit normal-case font-sans text-sm font-semibold text-gray-10 shadow-sm shadow-gray-shadow bg-default-white border border-solid border-gray-4 shadow-none rounded-none rounded-l-lg stroke-gray-10 whitespace-nowrap " +
      "hover:bg-gray-1 hover:border-gray-4 hover:shadow-none hover:shadow-gray-shadow " +
      "active:bg-primary-8 active:border-gray-4 active:text-default-white active:first:stroke-default-white " +
      "disabled:cursor-not-allowed"
  },
  {
    name: "middle",
    style:
      "min-w-fit normal-case font-sans text-sm font-semibold text-gray-10 shadow-sm shadow-gray-shadow bg-default-white border border-solid border-gray-4 border-l-0 shadow-none rounded-none stroke-gray-10 whitespace-nowrap " +
      "hover:bg-gray-1 hover:border-gray-4 hover:shadow-none hover:shadow-gray-shadow " +
      "active:bg-primary-8 active:border-gray-4 active:text-default-white active:first:stroke-default-white " +
      "disabled:cursor-not-allowed"
  },
  {
    name: "end",
    style:
      "min-w-fit  normal-case font-sans text-sm font-semibold text-gray-10 shadow-sm shadow-gray-shadow bg-default-white border border-solid border-gray-4 border-l-0 shadow-none rounded-none rounded-r-lg stroke-gray-10 whitespace-nowrap " +
      "hover:bg-gray-1 hover:border-gray-4 hover:shadow-none hover:shadow-gray-shadow " +
      "active:bg-primary-8 active:border-gray-4 active:text-default-white active:first:stroke-default-white " +
      "disabled:cursor-not-allowed"
  }
];

function SegmentedControl(props: Props) {
  const { message, variant, size, onClick, disabled } = props;

  const [style, setStyle] = useState<string>("");

  const handleStyleChange = () => {
    let cssStyle = segmentedControlProps.find((classes) => {
      return classes.name === variant;
    })?.style;

    if (size === "sm") cssStyle = `${cssStyle} px-3 py-1.5`;
    if (size === "bg") cssStyle = `${cssStyle} px-3 py-2.5`;

    setStyle(cssStyle ?? "");
  };

  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [variant, size]);

  return (
    <Box>
      <MuiButton className={style} onClick={onClick} disabled={disabled}>
        {message}
      </MuiButton>
    </Box>
  );
}

SegmentedControl.defaultProps = {
  variant: "start",
  size: "sm",
  disabled: false,
  onClick: null
};

export default SegmentedControl;
