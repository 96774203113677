export const API_ROUTES = {
  // TODO: add your api routes here
  /* enter your api routes here */
};

export const AUTH_ROUTES = { LOGIN: "/login" };

export const APP_ROUTES = {
  FIRM: "/firm",
  USER_SETTINGS: "/user-settings",
  USER_MANAGEMENT: "/user-management",
  CANDIDATES_MANAGEMENT: "/candidates-management",
  ATTORNEYS: "/attorneys",
  JOBS: "/jobs",
  JOB: "/jobs/:id",
  JOB_PUBLIC: "/jobs/public/:id",
  JOB_PUBLIC_DEMO: "/jobs/:id/public-demo",
  ADD_NEW_JOB: "/jobs/add-new-job",
  JOB_CANDIDATES: "/jobs/:id/candidates",
  MY_EMPLOYEES: "/my-employees",
  RETENTION: "/retention",
  NOT_FOUND: "/404",
  ACCESS_DENIED: "/access-denied",
  NO_USER: "/no-user"
};
