import { Box, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { colors } from "common/scss/themes";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "stateManagement/hooks";
import { APP_ROUTES } from "../../../constants/ROUTES";
import Avatar from "../../Avatar";
import Badge from "../../Badge";
import SearchInput from "../../FormComponents/SearchInput";
import { IconChevronDown } from "../../Icons/Icons";
import Modal from "../../Modal";
// eslint-disable-next-line import/no-cycle
import Impersonate from "../../Impersonate";

export interface Props {
  avatar: string;
  withSearch: boolean;
  onLogoutClick: () => void;
}

function UserMenu(props: Props) {
  const { avatar, withSearch, onLogoutClick } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const { shouldShowInProd } = useAppSelector((state) => state.permissions);

  const envMode =
    (process.env.ENV_LABEL ?? "").charAt(0).toUpperCase() +
    (process.env.ENV_LABEL ?? "").slice(1);
  const open = Boolean(anchorEl);

  const currentFirm = JSON.parse(
    localStorage.getItem("impersonateFirm") ?? "null"
  );

  const { canImpersonate, canManageUsers } = useAppSelector(
    (state) => state.permissions
  );
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (currentFirm === null && canImpersonate) {
      setOpenModal(true);
    }
  }, [currentFirm, canImpersonate]);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = (value: boolean) => {
    setOpenModal(value);
  };

  const handleNavigateOnClick = (route: string) => {
    navigate(route);
    handleClose();
  };

  const menuOptions = [
    {
      id: "user-settings",
      label: "User Settings",
      onClick: () => {
        handleNavigateOnClick(APP_ROUTES.USER_SETTINGS);
      },
      visible: true
    },
    {
      id: "user-management",
      label: "User Management",
      onClick: () => {
        handleNavigateOnClick(APP_ROUTES.USER_MANAGEMENT);
      },
      visible: canManageUsers
    },
    {
      id: "candidates-management",
      label: "Candidates Management",
      onClick: () => {
        handleNavigateOnClick(APP_ROUTES.CANDIDATES_MANAGEMENT);
      },
      visible: canManageUsers
    },
    {
      id: "plans-and-billing",
      label: "Plans And Billing",
      onClick: () => {
        handleNavigateOnClick(APP_ROUTES.PLANS_AND_BILLING);
      },
      visible: false
    },
    {
      id: "impersonate",
      label: "Impersonate",
      onClick: () => {
        setOpenModal(true);
        handleClose();
      },
      visible: canImpersonate
    }
  ];

  return (
    <Box>
      <Box position="relative">
        <Box display="flex" justifyContent="flex-end">
          {withSearch && (
            <Box>
              <SearchInput />
            </Box>
          )}
          {shouldShowInProd && (
            <Badge message={envMode} variant="neutral" type="Subtle" />
          )}
          <Box
            ml={2}
            display="flex"
            alignItems="center"
            onClick={(e: any) => handleClick(e)}
            className="cursor-pointer"
            id="user-menu"
          >
            <Avatar
              image={avatar}
              size="small"
              name={`${user?.first_name} ${user?.last_name}`}
            />
            <Typography
              component="span"
              className="font-sans text-gray-10 text-sm font-medium ml-2"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              {user?.first_name} {user?.last_name}
            </Typography>
            <IconChevronDown width={20} stroke={colors.gray[10]} />
          </Box>
        </Box>
        <Menu
          classes={{
            paper: "mt-2 bg-default-white rounded-md shadow-md w-52"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          autoFocus={false}
        >
          {menuOptions.map(
            (option) =>
              option.visible && (
                <MenuItem
                  key={option.label}
                  id={option.id}
                  classes={{
                    root: "font-sans font-medium text-sm px-4 py-2 hover:bg-primary-1 hover:text-primary-8 cursor-pointer"
                  }}
                  onClick={() => {
                    option.onClick();
                  }}
                >
                  {option.label}
                </MenuItem>
              )
          )}
          <Box px={1} py={1}>
            <Divider />
          </Box>
          <MenuItem
            onClick={() => {
              onLogoutClick();
            }}
            id="logout"
            classes={{
              root: "font-sans font-medium text-sm px-4 py-2 hover:bg-primary-1 hover:text-primary-8 cursor-pointer"
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Box>
      <Modal title="Impersonate" open={openModal} closeIcon={false}>
        <Impersonate handleModal={handleModal} />
      </Modal>
    </Box>
  );
}

export default UserMenu;
